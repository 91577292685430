import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import '../vendors/styles/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import JCN from "./customer/JCN";
import Newgen from "./customer/Newgen";
import JCS from "./customer/JCS";
import Tellustec from "./customer/Telustec";
import TokyoPipe from "./customer/Tokyo-Pipe";
import Home from "./customer/Home";
import Navigation from "./customer/Navigation";
import { RouteNames } from "../RouteNames";
import NotFound from "./common/NotFound";
import ProductList from "./customer/ProductList";
import Sakura from "./customer/Sakura";
import ProductDetails from "./customer/ProductDetails";
import Footer from "./customer/Footer";
import ContactUs from "./customer/ContactUs";
import bgImg from "../vendors/images/04.png";
import Brands from "./customer/Brands";
import { environment } from "../environment/environment";
import NewgenImg from "../vendors/images/NEWGEN.png";
import TOKYOPIPEImg from "../vendors/images/TOKYOPIPE.png";
import JCSImg from "../vendors/images/JCS.png";
import JCNImg from "../vendors/images/JCN.png";
import SakuraImg from "../vendors/images/SAKURA_Image.png";

function App() {
  const [categoryList, setCategoryList] = useState<any>([])
  const location = useLocation();
  
  useEffect(() => {
    
    fetch(`${environment.api_url}/product/getcategorys`)
    .then(response =>response.json())
    .then(data=>{
     setCategoryList(data)
    })
   }, [])

  useEffect(() => {
    switch (location.pathname) {
      case RouteNames.ROOT:
        document.body.style.backgroundImage = "unset";
        break;
      // case RouteNames.BRANDS:
      //   document.body.style.backgroundImage = `url(${bgImg})`;
      //   break;
      // case RouteNames.CONTACTUS:
      //   document.body.style.backgroundImage = `url(${bgImg})`;
      //   break;
      case RouteNames.SAKURA:
        document.body.style.backgroundImage = `url(${SakuraImg})`;
        break;
      case RouteNames.NEWGEN:
        document.body.style.backgroundImage = `url(${NewgenImg})`;
        break;
      case RouteNames.TELLUSTEC:
        document.body.style.backgroundImage = `url(${TOKYOPIPEImg})`;
        break;
      case RouteNames.TOKYOPIPE:
        document.body.style.backgroundImage = `url(${TOKYOPIPEImg})`;
        break;
      case RouteNames.JCS:
        document.body.style.backgroundImage =  `url(${JCSImg})`;
        break;
      case RouteNames.JCN:
        document.body.style.backgroundImage = `url(${JCNImg})`;
        break;
      default:
        document.body.style.backgroundImage = `url(${bgImg})`;
        break;
    }
  }, [location]);

  return (
    <>
      <Navigation category={categoryList}/>
      <Routes>
        <Route path={RouteNames.ROOT} element={<Home />} />
        <Route path={RouteNames.JCN} element={<JCN />} />
        <Route path={RouteNames.SAKURA} element={<Sakura />} />
        <Route path={RouteNames.NEWGEN} element={< Newgen/>} />
        <Route path={RouteNames.JCS} element={< JCS/>} />
        <Route path={RouteNames.TELLUSTEC} element={< Tellustec/>} />
        <Route path={RouteNames.TOKYOPIPE} element={< TokyoPipe/>} />
        <Route path={RouteNames.CONTACTUS} element={<ContactUs />} />
        <Route path={RouteNames.BRANDS} element={<Brands category={categoryList} />} />
        <Route path={RouteNames.PRODUCTDETAILS + "/:id" + "/:category" + "/:brand"} element={<ProductDetails />} />
        <Route path={RouteNames.PRODUCT_LIST + "/:cat_id" + "/:brand_id" + "/:category" +"/:brand"} element={<ProductList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
