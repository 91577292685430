import React from "react";
import companyLogo1 from "../../vendors/images/jcn_machinery_logo.png";

const JCN = () => {
  return (
    <div>
      <div>
        <div className="container company">
          <div className="row pt-4">
            <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
              <h1>JCN MACHINERY</h1>
              <div className="d-block d-lg-none mt-5 mb-5">
                <img src={companyLogo1} alt="Jcn machinery logo" style={{ width: "40%" }} />
              </div>
              <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                JCN Machinery is an extension of our group of companies to the Netherlands. We specialize in sale of construction machinery within Europe and to other countries.
                <br /><br />
                JCN Machinery is the local dealer in the Netherlands for high quality Benza products from Spain. Being based in
                the Netherlands, a very multi-national country, JCN Machinery has had the opportunity of connecting with
                highly professional individuals of various nationalities that have helped us in different dimensions for the wide
                range of work being done from our group of companies.
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
              <div className="d-flex justify-content-center">
                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JCN;
