import { NavLink } from 'react-router-dom'
import { RouteNames } from '../../RouteNames'
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../../vendors/styles/nav.css';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
//import  Switch from "react-router-dom";
import React from "react";

import { Row, Col, NavDropdown } from "react-bootstrap";

import "react-bootstrap-submenu/dist/index.css";

const Navigation = (props:any) => {
  const categories = props.category;

  return (
    <Navbar collapseOnSelect expand="lg" className="nav">
      <Navbar.Brand href=""></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <div className="spacer"></div>
        <Nav className="ml-auto" >
            <NavLink className="nav-link" to={RouteNames.ROOT}>Home</NavLink>
            <NavDropdown title="Products" id="collasible-nav-dropdown">  
              {categories.map((item:any)=>(
                      <NavDropdown.Item><Link to={RouteNames.BRANDS+`?category=${item.name}`}>{item.name}</Link></NavDropdown.Item>
                    ))}
            </NavDropdown>
            <NavLink className="nav-link" to={RouteNames.CONTACTUS}>Contact us</NavLink>
        </Nav>
      </Navbar.Collapse>
  </Navbar>
  )
}

export default Navigation