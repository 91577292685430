import React from "react";
import JcsLogo from "../../vendors/images/l2.png";
import ToktoPipeLogo from "../../vendors/images/l3.png";
import TellustecLogo from "../../vendors/images/l4.png";
import NewgenLogo from "../../vendors/images/l5.png";
import SakuraLogo from "../../vendors/images/l6.png";
import JcnMachineryLogo from "../../vendors/images/jcn_machinery_logo.png";

const ContactUs = () => {
  return (
    <div className="contactus-container pt-5 pb-5">
      <div className="container ">
        <div className="row mb-5">
          <h1 className="mb-3 mt-3">Contact us</h1>
          <div className="col-sm-12 col-md-12 col-lg-6 mt-4c mb-5">
            <div className="row m-auto mt-5">
              <div className="col-4">
                <img src={SakuraLogo} alt="Jcsakura logo" style={{ maxWidth: "100px",width:"100%", float: "right" }} />
              </div>
              <div className="col-8">
                JC Sakura International PVT LTD <br /> info@jcsakurainternational.com <br />
                <a href="tel:+94 112 150 615">+94 112 150 615</a> <br />
                <a href="tel:+94 773 779 997">+94 773 779 997</a>
              </div>
            </div>
            <div className="row m-auto mt-5">
              <div className="col-4">
                <img src={NewgenLogo} alt="Newgen logo" style={{ maxWidth: "150px",width:"100%", float: "right" }} />
              </div>
              <div className="col-8">
                Newgen Lifescience PVT LTD <br /> sales@newgenlifescience.com <br /> +94 112 150 615 <br /> +94 773 779 997
              </div>
            </div>
            <div className="row m-auto mt-5">
              <div className="col-4">
                <img src={TellustecLogo} alt="Tellustec logo" style={{ maxWidth: "150px",width:"120%", float: "right" }} />
              </div>
              <div className="col-8">
                Tellustec Co. Ltd - Japan <br /> m.sekiguchi@tellustec.co.jp <br /> cnagahawattha@tellustec.co.jp <br /> +81 368 082 642{" "}
                <br /> +81 9025 506 199
              </div>
            </div>
            <div className="row m-auto mt-5">
              <div className="col-4">
                <img src={ToktoPipeLogo} alt="Newgen logo" style={{ maxWidth: "120px",width:"100%", float: "right" }} />
              </div>
              <div className="col-8">
                Tokyo Pipe Engineering (Pvt) Ltd <br /> md@tokyopipeeng.com <br /> +94 112 150 615 <br /> +94 773 779 997
              </div>
            </div>
            <div className="row m-auto mt-5">
              <div className="col-4">
                <img src={JcsLogo} alt="Newgen logo" style={{ maxWidth: "120px",width:"100%", float: "right" }} />
              </div>
              <div className="col-8">
                JCS Machinery (Pvt) Ltd <br /> sales@jcsmachinery.com <br /> +94 112 150 615 <br /> +94 773 779 997
              </div>
            </div>
            <div className="row m-auto mt-5">
              <div className="col-4">
                <img src={JcnMachineryLogo} alt="Newgen logo" style={{ maxWidth: "160px",width:"100%", float: "right" }} />
              </div>
              <div className="col-8">
                JCN Machinery (Pvt) Ltd - The Netherlands <br /> sales@jonmachinery.com <br /> +31 683 451 948
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 mt-4 mb-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.474487956411!2d79.90356071532592!3d6.833572321375452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25be8a4123b09%3A0x3bcf152c912facba!2sJC%20Sakura%20International!5e0!3m2!1sen!2slk!4v1647624916810!5m2!1sen!2slk"
              width="100%"
              height="450"
              style={{ border: "0", borderRadius: "12px" }}
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div className="contactus-form mt-5">
          <form>
            <div className="form-group mb-3">
              <label htmlFor="inputName" className="mb-2">
                Name
              </label>
              <input type="text" className="form-control shadow-sm" id="inputName" placeholder="Enter name" />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputEmail1" className="mb-2">
                Email address
              </label>
              <input
                type="email"
                className="form-control shadow-sm"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="inputTopic" className="mb-2">
                Topic
              </label>
              <input type="text" className="form-control shadow-sm" id="inputTopic" placeholder="Enter a topic" />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="descriptionTextarea" className="mb-2">
                Your message
              </label>
              <textarea className="form-control shadow-sm" id="descriptionTextarea" rows={3}></textarea>
            </div>
            <button type="submit" className="btn btn-primary mb-3 shadow-none">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
