export enum RouteNames{
    ROOT = '/',
    HOMESCREEN = '/home',
    JCN = '/jcn',
    SAKURA = '/sakura',
    CONTACTUS = '/contact-us',
    TELLUSTEC = '/tellustec',
    TOKYOPIPE = '/tokyo-pipe',
    JCS = '/jcs',
    NEWGEN = '/newgen',
    PRODUCTDETAILS = '/product-details',
    BRANDS = '/brands',
    PRODUCT_LIST = '/product-list'
}