import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { environment } from "../../environment/environment";
import "../../vendors/styles/brands.css";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteNames } from "../../RouteNames";

const Brands = (props: any) => {
  const [categories, setCat] = useState(props.category);
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const search = useLocation().search;
  const currentCategoryQueryParms = new URLSearchParams(search).get("category");

    
  useEffect(() => {
    // console.log(categories)
    let catWithBrands = Promise.all(
      categories.map((category: any) => {
        return fetch(`${environment.api_url}/product/getCategoryBrands/${category.id}`)
          .then((response) => response.json())
          .then((data) => {
            return {
              ...category,
              brands: data,
            };
          });
      })
    );

    catWithBrands.then((data) => {
      setCat(data);
      setIsLoading(false);
    });
 
  }, []);
  const index = categories.findIndex((object:any) => {
    return object.name == currentCategoryQueryParms;
  });
  if(index != 0){
    let tempCategories = categories
    let temp = tempCategories[index]
    tempCategories[index] = tempCategories[0]
    tempCategories[0] =temp
    setCat(tempCategories)
  }
  const selectBrand = (brand: any, category: any) => {
    history(RouteNames.PRODUCT_LIST + `/${category.id}/${brand.id}/${category.name}/${brand.name}`);
  };

  const getBrandsView = (category: any) => {
    let brands = category["brands"];

    return brands.map((brand: any, i: any) => (
      <div key={i} className="col-md-3 col-sm-6 col-12 mb-3 d-flex justify-content-center">
        <Card onClick={() => selectBrand(brand, category)} style={{ width: "18rem" }} className="brands-card">
          <div className="card-bg">
            <Card.Img variant="top" src={require(`../../vendors/logos/${brand.name}.png`)} />
          </div>
          <Card.Body className="d-flex justify-content-center align-items-center">
            <Card.Title className="text-center title">{brand.name}</Card.Title>
          </Card.Body>
        </Card>
      </div>
    ));
  };

  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-12">
          {categories.map((category: any, i: number) => (
            <div key={i}>
              <div className="mt-5" id={category.name}>
                <div className="brand-strip">{category.name} </div>
              </div>

              <div className="brands">
                <div className="mt-4">
                  {isLoading ? (
                    <div className="d-flex justify-content-center">
                      <h5 className="fa-fade">Loading...</h5>
                    </div>
                  ) : (
                    <div className="row mt-3">{getBrandsView(category)}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
