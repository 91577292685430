import '../../vendors/styles/product_details.css';
import { environment } from '../../environment/environment';
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RouteNames } from '../../RouteNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';

const ProductDetails = () => {

  const { id, category, brand } = useParams();
  const [productData, setProductData] = useState<any>();
  const history = useNavigate();

  useEffect(() => {
    fetch(`${environment.api_url}/product/getProduct/${id}`)
      .then(response => response.json())
      .then(data => {
        setProductData(data)
        console.log(data)
      })
  }, []);

  const selectBrand=(productData:any)=>{
    history(RouteNames.PRODUCT_LIST + `/${productData.categoryId}/${productData.brandId}/${category}/${brand}`);
  }

  return (
    <div className="product-details-wrapper container">
      <p>
        <Link to={RouteNames.ROOT}>Home</Link> &gt; <Link to={RouteNames.BRANDS+`?category=${category}`}>{category}</Link> &gt; <a href="" onClick={() => selectBrand(productData)}>{brand}</a> &gt; {productData?.name}</p>
      <div className="container ">
        <div className="row product-details-container">
          <div className="col-12 col-sm-6">
            <img src={`${environment.img_url}/${productData?.imageUrl}`} alt="" />
          </div>
          <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center product-details">
            <div className="product-description">
              <h1 className="mb-4">{productData?.name}</h1>
              <p className="mb-5">{productData?.description}</p>
              <FontAwesomeIcon icon={faFileLines} />
              <a href={productData?.spec} target="_blank">Product Brochure</a>
              <h5>{productData?.feature}</h5>
            </div>
          </div>
        </div>

        {
          productData?.video != "" ? (
            <div className="wrapper">
              <div className="videowrapper">
                <iframe src={productData?.video} loading="lazy" allow="accelerometer; gyroscope; clipboard-write; encrypted-media; picture-in-picture;" allowFullScreen></iframe>
              </div>
            </div>
          ) : null
        }
        
      </div>
    </div>
  )
}

export default ProductDetails