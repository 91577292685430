import React from "react";
import sakura from "../../vendors/images/sakura.png"

const Sakura = () => {
    return (
        <div>
            <div>
                <div className="container company">
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <h1>JC Sakura International</h1>
                            <div className="d-block d-lg-none mt-5 mb-5">
                                <img src={sakura} alt="JC Sakura logo" style={{ width: "40%" }} />
                            </div>
                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                            JC Sakura International company being the agent for Kranzle products from Germany (through Internaco in Spain), Italclean and Grandimpianti from Italy, we specialize in laundry equipment through this company.
                            Our deep understanding of the laundry equipment industry allows us to provide bespoke commercial washing machine solutions for your business.
                            <br /><br />
                             In addition to the quality, reliability and performance you'd expect as standard, you can benefit
                            from business-focused features such as eco-friendly cycles, infection control technology and rigid mount or free-standing options — as well as customizable programs.
                            Whatever your requirements are for laundry equipment, we can provide you with everything you need to ensure your business thrives. Being the agent of Grandimpianti and ltalclean, we supply all solutions to your varied laundry equipment needs.
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={sakura} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sakura;