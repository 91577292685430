import React from "react";
import companyLogo1 from "../../vendors/images/l5.png";

const Newgen = () => {
    return (
        <div>
            <div>
                <div className="container company">
                    <div className="row pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-6 mt-5 mb-5">
                            <h1>NEWGEN LIFESCIENCE (PVT) LTD</h1>
                            <div className="d-block d-lg-none mt-5 mb-5">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                            <div className="shadow-sm pe-5 ps-5 pt-4 pb-3 mt-3 mt-md-5 bg-white rounded-3 lh-lg" style={{ color: "#3551a3", textAlign: 'justify' }}>
                                We are one of the leading importers and distributors of pharmaceuticals, surgical and diagnostic equipment
                                and food supplement products for the local market.
                                <br /><br />
                                Our contribution in the field has already helped to shape the health care of people by making available quality
                                products from European producers. In addition to providing aspects of health improvements to the lives of
                                patients throughout the country, we also provide our overseas principals to the best of our services.
                                <br /><br />
                                As the agent for Novelty Pharma of Switzerland, Redwing British Labs of England and Westcoast
                                Pharmaceuticals, Orion Lifescience, both of India, Newgen Lifescience (Pvt) Ltd. has established itself with a
                                substantial percentage of the market and reached the top spot as one ofthe leading importers and distributors
                                of pharmaceuticals, surgical and diagnostic equipment and food supplement products locally.
              </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mt-5 d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={companyLogo1} alt="Jcn machiney logo" style={{ width: "40%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newgen;
